.goal__flex {
    display: flex;
    align-items: center;
    background: #2C2C2C;
    border-radius: 8px;

    &:not(:last-child) {
        margin-bottom: 128px;
    }

    &:nth-child(2n) {
        flex-direction: row-reverse;

        .goal__img {
            height: 600px;
            margin-right: 0;
            margin-left: 91px;
            img{
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.goal__img {
    height: 600px;
    max-width: 648px;
    margin-right: 91px;
    img{
        height: 100%;
        object-fit: cover;
    }
}

.goal__info {
    max-width: 500px;

    h3 {
        font-size: 24px;
        line-height: 29px;
        color: #F2F2F2;
    }

    p {
        font-size: 16px;
        line-height: 150%;
        color: #9E9E9E;
        margin-top: 19px;
        margin-bottom: 120px;
    }

    .goal__comany {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        img {
            max-width: 150px;
        }
    }
}